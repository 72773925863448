<template>
  <v-form ref="form" lazy-validation>
    <v-dialog
      v-model="dialog"
      max-width="700px"
      persistent
      transition="dialog-top-transition"
      @keydown.esc="close"
    >
      <v-card>
        <v-card-title
          >{{ editedItem.wellname }}&nbsp;&nbsp;{{ editedItem.testname }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <datepicker
                label="Alternative Date"
                v-model="editedItem.event_date"
                :rules="[(v) => !!v || 'Waiver Date required']"
                :key="cs"
                :edit="true"
                :date_min="editedItem.due_date"
              ></datepicker>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                label="comment"
                return-object
                autocomplete="off"
                v-model="editedItem.comment"
                row-height="30"
                rows="2"
                variant="outlined"
                auto-grow
                outlined
                dense
              >
              </v-textarea>
            </v-col>
            <filelist
              :item="editedItem"
              :editer="true"
              :doc_type="parseInt(editedItem.testtype_id)"
              :isform="false"
              :auth="true"
              :well_id="parseInt(editedItem.well_id)"
              :key="file_key"
              :headers="docs_headers"
            >
            </filelist>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="ma-1" color="blue darken-1" @click.stop="save()">
            Save
          </v-btn>
          <v-btn class="ma-1" color="blue darken-1" @click="closeDialog()">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog></v-form
  >
</template>
<script>
import EventCreate from "../graphql/Planning/EventCreate.gql";
import EventUpdate from "../graphql/Planning/EventUpdate.gql";
export default {
  components: {
    datepicker: () => import("../components/DatePicker.vue"),
    filelist: () => import("./FileList.vue"),
  },
  props: {
    dialog: Boolean,
    waiver: Object,
  },
  data() {
    return {
      file_key: 0,
      editedItem: {},
      cs: 1000,
      docs_headers: [
        {
          text: "No",
          value: "no",
          selected: true,
          sortable: false,
          index: 0,
        },
        {
          text: "Reference",
          value: "ref",
          enum: "REF",
          selected: true,
          index: 1,
        },
        {
          text: "Description",
          value: "description",
          enum: "DESCRIPTION",
          selected: true,
          index: 2,
        },
        {
          text: "File/Url",
          value: "filename",
          enum: "FILENAME",
          slot: "preview",
          preview: "preview",
          selected: true,
          index: 3,
        },
        {
          text: "Size (ko)",
          value: "size",
          enum: "SIZE",
          align: "end",
          slot: "cur33",
          selected: false,
          index: 5,
        },
        {
          text: "Date",
          value: "doc_date",
          enum: "DOC_DATE",
          slot: "date",
          selected: true,
          index: 6,
        },
        {
          text: "Url?",
          value: "isurl",
          slot: "checkbox",
          enum: "ISURL",
          selected: false,
          hiden: true,
          index: 7,
        },
        {
          text: "is Active?",
          value: "isActive",
          slot: "checkbox",
          enum: "ACTIVE",
          selected: false,
          hiden: true,
          index: 8,
        },
        {
          text: "User",
          value: "contact",
          enum: "CONTACT",
          selected: false,
          hiden: true,
          index: 9,
        },
        {
          text: "Test",
          value: "test",
          enum: "TEST",
          selected: false,
          hiden: true,
          index: 10,
        },
        {
          text: "",
          slot: "col_btn1",
          icon: "mdi-pencil",
          selected: true,
          width: "10",
          column: "create_uid",
          valeur: this.$store.state.me.id,
          tooltip: "Update document",
          sortable: false,
          index: 11,
        },
        {
          text: "",
          slot: "col_btn2",
          icon: "mdi-download",
          selected: true,
          width: "10",
          tooltip: "Download document",
          column1: "isurl",
          valeur1: 0,
          sortable: false,
          index: 12,
        },
      ],
    };
  },
  watch: {},
  mounted() {
    this.editedItem = Object.assign({}, this.waiver);
    this.cs++;
    this.file_key++;
  },
  computed: {},
  methods: {
    closeDialog(item) {
      this.$emit("WaiverClose", item);
    },
    save() {
      if (this.editedItem.id) this.UpdateEvent();
      else this.AddEvent();
    },
    UpdateEvent() {
      let v = {
        id: this.editedItem.id,
        event_statut: 1,
        test_id: this.editedItem.test_id,
        event_date: this.editedItem.event_date,
        comment: this.editedItem.comment,
      };
      this.$apollo
        .query({
          query: EventUpdate,
          variables: {
            updatetestevent: v,
          },
          fetchPolicy: "no-cache",
        })
        .then(() => {
          this.closeDialog(this.editedItem.planning_id);
        })
        .catch(() => {});
    },
    AddEvent() {
      let v = {
        test_id: this.editedItem.test_id,
        event_date: this.editedItem.event_date,
        comment: this.editedItem.comment,
        planning_id: this.editedItem.planning_id,
        operator_id: this.$store.state.me.id,
        event_statut: 1,
        event_id: 1,
      };
      this.$apollo
        .query({
          query: EventCreate,
          variables: {
            createtestevent: v,
          },
          fetchPolicy: "no-cache",
        })
        .then(() => {
          // this.editedItem.id = data.data.createTestEvent.id;
          // this.editedItem.statut = "Task";
          this.closeDialog(this.editedItem.planning_id, 1);
        })
        .catch(() => {});
    },
  },
};
</script>
